import { RegisterEnum } from '@/enums/registers';
import CercMapper from './CERC';
import TagMapper from './TAG';

export default class ConsultMapperFactory {
    static generate(
        register,
    ) {
        if (RegisterEnum.cerc === register) { return new CercMapper(); }
        if (RegisterEnum.tag === register) { return new TagMapper(); }

        throw new Error('not exists register');
    }
}
