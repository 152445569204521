<template>
  <div>
    <b-row>
      <b-col md="6">
        <b-form-group
          label="Tipo"
          label-for="type"
        >
          <b-form-select
            id="type"
            v-model="type"
            :disabled="param === 'per_ur'"
            :options="[
              { value: 'start', text: 'Inicio > Fim'},
              { value: 'end', text: 'Fim > Inicio'},
              { value: 'percentage', text: 'Porcentagem'}
            ]"
          />
        </b-form-group>
      </b-col>

      <b-col md="6">
        <b-form-group
          label="Valor"
          label-for="effect-value"
        >
          <validation-provider
            #default="{ errors }"
            name="Valor"
            :rules="`required|effective-value|calculator-value:${availableAmountBetweenDate}`"
          >
            <b-form-input
              id="effect-value"
              v-model="value"
              v-money="{
                decimal: ',',
                thousands: '.',
                prefix: 'R$ ',
                precision: 2,
                masked: false,
              }"
              :disabled="param === 'per_ur'"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col
        v-if="type === 'percentage'"
        md="12"
      >
        <b-form-group
          label="Porcentagem"
          label-for="percentage"
        >
          <validation-provider
            #default="{ errors }"
            name="Porcentagem"
            rules="required|percent"
          >
            <b-input-group append="%">
              <b-form-input
                id="percentage"
                v-model="percentage"
                :disabled="param === 'per_ur'"
                :state="errors.length > 0 ? false : null"
              />
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
    BRow, BCol, BFormGroup, BFormInput, BFormSelect, BInputGroup,
} from 'bootstrap-vue';

import { ValidationProvider } from 'vee-validate';

const removeSymbolsMoney = value => (typeof value === 'string'
    ? +value.replace(/[R$ .]/g, '').replace(',', '.')
    : value);

export default {
    components: {
        BRow, BCol, BFormGroup, BFormInput, BFormSelect, BInputGroup, ValidationProvider,
    },

    props: {
        availableTotal: { type: Number, required: true },
        param: { type: String, required: true },
        availableAmountBetweenDate: { type: Number, required: true },
        guarantee: { type: Number, required: true },
    },

    data() {
        return {
            percentage: null,
            value: 'R$ 1,00',
            type: 'start',
        };
    },

    watch: {
        value(newValue) {
            this.$emit('set-value', removeSymbolsMoney(newValue));
        },

        percentage(newValue) {
            this.$emit('handle-change-percentage', newValue);
        },

        type(newValue) {
            this.$emit('handle-change-type', newValue);
        },

        param(newValue) {
            if (newValue === 'per_ur') {
                this.type = 'percentage';
                this.percentage = 100 - this.guarantee;
                this.value = this.availableAmountBetweenDate.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
            }
        },

        guarantee(
            value,
        ) {
            if (this.param === 'per_ur') {
                this.percentage = 100 - value;
                this.value = this.availableAmountBetweenDate.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
            }
        },
    },
};
</script>
