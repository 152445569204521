var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.schedule)?_c('div',[_c('b-card',[_c('Matrix',{attrs:{"hide-pagination":"","primary-fields":[
          { key: 'primary', label: ''},
          { key: 'due', label: 'A Expirar'},
          { key: 'expired', label: 'Expirado'},
          { key: 'total', label: 'total'} ],"items":_vm.summaryData}})],1),(!_vm.objectIsEmpty(_vm.debtorsMatrixItems))?_c('b-card',[_c('Matrix',{attrs:{"primary-fields":_vm.debtorsMatrixPrimaryFields,"items":_vm.debtorsMatrixItems}})],1):_vm._e(),(!_vm.objectIsEmpty(_vm.contractHolderMatrixItems))?_c('b-card',[_c('Matrix',{attrs:{"primary-fields":_vm.contractHolderMatrixPrimaryFields,"primary-key":"payment_scheme","secondary-key":"contract_holder","item-key":"total_amount","items":_vm.contractHolderMatrixItems}})],1):_vm._e(),(_vm.schedule.register === 'TAG')?_c('UR',{attrs:{"urs-available":_vm.schedule.additional_information.total_due_urs,"available-amount":_vm.summaryData[2].due,"items":_vm.ursData,"urs-commited":_vm.summaryData[0].expired,"total":_vm.summaryData[0].total,"total-available":_vm.summaryData[0].due,"constituted":_vm.removeSymbolsMoney(_vm.summaryData[3].due),"fields":[
        { key: 'expected_settlement_date', label: 'Data da UR', sortable: true },
        { key: 'acquirer', label: 'Adquirente' },
        { key: 'payment_scheme', label: 'Esquema' },
        { key: 'committed_amount', label: 'comprometido', sortable: true },
        { key: 'committed_effect_amount', label: 'autorizado', sortable: true },
        { key: 'uncommitted_amount', label: 'Disponível', sortable: true },
        { key: 'settled_amount', label: 'Liquidado', sortable: true },
        { key: 'total_amount', label: 'Total', sortable: true },
        { key: 'time', label: 'Prazo'},
        { key: 'effect', label: 'Efeito' } ]}}):_c('CercUR',{attrs:{"urs-available":_vm.schedule.additional_information.total_due_urs,"available-amount":_vm.summaryData[2].due,"items":_vm.ursData,"urs-commited":_vm.summaryData[0].expired,"total":_vm.summaryData[0].total,"constituted":_vm.removeSymbolsMoney(_vm.summaryData[3].due),"total-available":_vm.summaryData[0].due,"fields":[
        { key: 'expected_settlement_date', label: 'Data da UR', sortable: true },
        { key: 'acquirer', label: 'Adquirente' },
        { key: 'payment_scheme', label: 'Esquema' },
        { key: 'committed_amount', label: 'comprometido', sortable: true },
        { key: 'uncommitted_amount', label: 'Disponível', sortable: true },
        { key: 'settled_amount', label: 'Liquidado', sortable: true },
        { key: 'total_amount', label: 'Total', sortable: true },
        { key: 'time', label: 'Prazo'},
        { key: 'effect', label: 'Efeito' } ]}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }