import formatTaxId from '@/filters/taxId';

export const exportExcel = (items, doUncommittedAmount) => items.map(item => ({
    'Data da UR': item.expected_settlement_date,
    Adquirente: formatTaxId(item.acquirer),
    Esquema: item.payment_scheme,
    Comprometido: item.committed_amount,
    Disponível: doUncommittedAmount(item).toFixed(2),
    Liquidado: item.settled_amount,
    Total: item.total_amount.toFixed(2),
    Efeito: item.hasEffect ? 'SIM' : 'NÃO',
}));
