export default class TagMapper {
    // <span v-if="!data.item.settlement_obligations[0].contract_obligations.length">0</span>
    //     <span v-else-if="data.item.settlement_obligations[0].contract_obligations[0].percentage_value">
    //       {{ `${data.item.settlement_obligations[0].contract_obligations.reduce((acc, curr) => {
    //         return acc + (curr.percentage_value / 100)
    //       }, 0)} %` }}</span>

    //     <span v-else>
    //       {{ data.item.settlement_obligations[0].contract_obligations.reduce((acc, curr) => {
    //         return acc + curr.effect_amount
    //       }, 0) | value }}
    //     </span>
    // eslint-disable-next-line class-methods-use-this
    specification(data) {
        return data.map(item => {
            const { settlement_obligations: obligations } = item;
            const [obligation] = obligations;

            const { contract_obligations: contracts } = obligation;

            let commmittedAmount = 0;
            let isPercentage = false;

            const hasEffect = !!contracts.length;

            if (!hasEffect) {
                commmittedAmount = 0;
            } else if (contracts[0].percentage_value) {
                commmittedAmount = contracts[0].percentage_value / 100;
                isPercentage = true;
            } else {
                commmittedAmount = contracts.reduce((acc, curr) => acc + curr.effect_amount, 0);
            }

            return {
                ...item,
                committed_amount: commmittedAmount,
                is_percentage: isPercentage,
                hasEffect,
            };
        });
    }

    // eslint-disable-next-line class-methods-use-this
    map(data) {
        return {
            ...data,
            settlement_expectations: this.specification(data.settlement_expectations),
        };
    }
}
