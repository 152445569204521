<template>
  <ValidationObserver
    ref="calculator-content"
    #default="{ invalid }"
  >
    <Header
      :value="{
        available: availableTotal,
        constituted: constituted
      }"
      :date="{
        max: maxDate,
        min: minDate
      }"
    />
    <b-row>
      <b-col
        class="m-0"
        md="12"
      >
        <Interval :dates="interval" />
      </b-col>

      <b-col md="12">
        <p v-if="interval.startDate && interval.endDate">
          Constituido entre os dias {{ interval.startDate | date }} {{ interval.endDate | date }}: {{ availableConstitutedBetweenDate | value }}
        </p>

        <p v-if="interval.startDate && interval.endDate">
          Disponível entre os dias {{ interval.startDate | date }} {{ interval.endDate | date }}: {{ availableAmountBetweenDate | value }}
        </p>

        <p v-if="interval.startDate && interval.endDate && type==='percentage'">
          Disponível entre os dias {{ interval.startDate | date }} {{ interval.endDate | date }} com a porcetangem de {{ percentage }}%: {{ (availableAmountBetweenDate * percentage) / 100 | value }}
        </p>
      </b-col>
    </b-row>

    <b-form-group
      label-for="has-guarantee"
      label="Possui Garantia?"
    >
      <b-form-checkbox
        id="has-guarantee"
        v-model="hasGuarantee"
      />
    </b-form-group>

    <Guarantee
      v-if="hasGuarantee"
      :available="availableAmountBetweenDate"
      :constituted="availableConstitutedBetweenDate"
      @calcule="handleCalculeGuarantee"
    />

    <Value
      :available-total="calcule.availableTotal"
      :available-amount-between-date="type === 'percentage' ? (availableAmountBetweenDate * percentage) / 100 : availableAmountBetweenDate"
      :percentage="percentage"
      :guarantee="calcule.guaranteeValue || 0"
      :param="calcule.param"
      @set-value="(v) => value = v"
      @handle-change-type="(v) => type = v"
      @handle-change-percentage="(v) => percentage = v"
    />
    <b-row class="mb-1">
      <b-col
        md="4"
      >
        <b-button
          style="width: 100%"
          :disabled="invalid"
          variant="primary"
          @click="calculate(type === 'start' ? sortURS : type === 'end' ? sortURSReverse : createPercentage)"
        >
          Calcular
        </b-button>
      </b-col>
      <b-col md="4">
        <b-button
          :disabled="!items.length"
          variant="primary"
          style="width: 100%"
          @click="createEndorsement"
        >
          Criar Averbação
        </b-button>
      </b-col>

      <b-col md="4">
        <b-button
          :disabled="!items.length"
          variant="primary"
        >

          <vue-json-to-csv
            :json-data="generateExcel()"
            :labels="{
              expected_settlement_date: {
                title: 'previsao_da_liquidacao',
              },
              receivable_debtor: { title: 'cnpj_adquirente' },
              payment_scheme: { title: 'esquema_de_pagamento' },
              effect_value: { title: 'valor_efetivo' },
            }"
            :separator="';'"
            csv-title="contrato"
            type="csv"
          >
            Download Modelo Excel
          </vue-json-to-csv>
        </b-button>
      </b-col>
    </b-row>

    <TableContractSpecification
      v-if="items.length"
      :fields="[
        { key: 'expected_settlement_date', label: 'previsão da liquidação' },
        { key: 'receivable_debtor', label: 'cnpj adquirente' },
        { key: 'payment_scheme', label: 'esquema' },
        { key: 'effect_value', label: 'Valor Efetivo' },
      ]"
      :schemes="schemes"
      :money-mask="money"
      :contract-specifications="items"
    />
  </ValidationObserver>
</template>

<script>
import {
    BRow, BButton, BCol, BFormCheckbox, BFormGroup,
} from 'bootstrap-vue';

import { ValidationObserver } from 'vee-validate';
import { DateTime } from 'luxon';
import VueJsonToCsv from 'vue-json-to-csv';
import Guarantee from './components/Guarantee.vue';

import calculator from './calculator';
import schemesPriority from './priority-schemes';
import TableContractSpecification from '@/views/pages/CreditPlatform/Endorsement/Components/ContractSpecifications/Table.vue';

import Interval from './components/Interval.vue';

import schemes from '@/utils/schemes';

import Value from './components/Value.vue';

import '@validations';

import taxId from '@/filters/taxId';
import value from '@/filters/value';
import date from '@/filters/date';

import Header from './components/Header.vue';

const removeSymbolsMoney = value => (typeof value === 'string'
    ? +value.replace(/[R$ .]/g, '').replace(',', '.')
    : value);

export default {
    components: {
        BFormCheckbox,
        BFormGroup,
        BRow,
        BButton,
        BCol,
        TableContractSpecification,
        ValidationObserver,
        VueJsonToCsv,
        Header,
        Guarantee,
        Interval,
        Value,
    },

    props: {
        availableAmount: {
            type: String,
            required: true,
        },

        urs: {
            type: Array,
            required: true,
        },

        constituted: {
            type: Number,
            required: true,
        },
    },

    data() {
        return {
            interval: {
                startDate: null,
                endDate: null,
            },
            value: null,
            percentage: 100,
            totalValue: 0,
            type: 'start',
            schemes,
            items: [],
            hasGuarantee: false,
            calcule: {
                available: removeSymbolsMoney(this.availableAmount),
                constituted: this.constituted,
                guaranteeValue: 0,
                param: 'available',
                availableTotal: 0,
            },

        };
    },

    computed: {
        currentDate() {
            return DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd');
        },
        filteredUrs() {
            return this.urs.filter(item => item.expected_settlement_date !== this.currentDate && item.expected_settlement_date > this.currentDate);
        },
        filteredUrsSorted() {
            const urs = JSON.parse(JSON.stringify(this.filteredUrs));

            return urs.sort((a, b) => a.expected_settlement_date.localeCompare(b.expected_settlement_date));
        },
        availableUrsBetweenDate() {
            return this.filteredUrs.filter(item => item.expected_settlement_date >= this.interval.startDate && item.expected_settlement_date <= this.interval.endDate);
        },
        availableAmountBetweenDate() {
            return this.availableUrsBetweenDate.reduce((acc, item) => acc + item.uncommitted_amount, 0);
        },
        availableConstitutedBetweenDate() {
            return this.availableAmountBetweenDate + this.availableUrsBetweenDate.reduce((acc, item) => acc + item.committed_effect_amount, 0);
        },
        availableAmountBetweenDatePerCent() {
            return (this.availableAmountBetweenDate * this.percentage) / 100;
        },
        availableTotal() {
            return this.filteredUrs.reduce((acc, item) => acc + item.uncommitted_amount, 0);
        },
        maxDate() {
            return this.filteredUrsSorted[this.filteredUrsSorted.length - 1].expected_settlement_date;
        },
        minDate() {
            return this.filteredUrsSorted[0].expected_settlement_date;
        },
    },

    watch: {
        constituted(value) {
            this.calcule.constituted = value;
        },

        calcule(v) {
            if (v.param === 'per_ur') {
                this.type = 'percentage';
                this.percentage = 100 - +v.guaranteeValue;
                this.value = this.availableAmountBetweenDatePerCent.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
            }
        },

        hasGuarantee(v) {
            if (!v) {
                this.calcule = {
                    available: removeSymbolsMoney(this.availableAmount),
                    constituted: this.constituted,
                    guaranteeValue: 0,
                    param: 'available',
                    availableTotal: 0,
                };
            } else {
                this.calcule = {
                    ...this.calcule,
                    param: 'per_ur',
                };
            }
        },
    },

    methods: {
        sortURS() {
            return this.availableUrsBetweenDate.sort((a, b) => {
                const dateA = a.expected_settlement_date.split('-').join('');
                const dateB = b.expected_settlement_date.split('-').join('');

                if (dateA === dateB) {
                    const reA = schemesPriority.indexOf(a.payment_scheme);
                    const reB = schemesPriority.indexOf(b.payment_scheme);

                    return (reA === -1 ? 1 : reB === -1 ? -1 : reA - reB);
                }

                return dateA.localeCompare(dateB);
            });
        },

        calculatePercentage(value, percentage) {
            return (value * percentage) / 100;
        },

        createPercentage() {
            const urs = this.sortURS();
            return urs.map(item => ({ ...item, uncommitted_amount: this.calculatePercentage(item.uncommitted_amount, this.percentage) }));
        },

        removeSymbolsMoney(valueWithMask) {
            return removeSymbolsMoney(valueWithMask);
        },

        sortURSReverse() {
            return this.availableUrsBetweenDate.sort((a, b) => {
                const dateA = a.expected_settlement_date.split('-').join('');
                const dateB = b.expected_settlement_date.split('-').join('');

                if (dateA === dateB) {
                    const reA = schemesPriority.indexOf(a.payment_scheme);
                    const reB = schemesPriority.indexOf(b.payment_scheme);

                    return (reA === -1 ? 1 : reB === -1 ? -1 : reA - reB);
                }

                return dateB.localeCompare(dateA);
            });
        },

        calculate(sortMethod) {
            const result = calculator(sortMethod(), this.removeSymbolsMoney(this.value));
            this.items = result;
            this.totalValue = result.reduce((acc, item) => acc + item.effect_value, 0);
        },

        createEndorsement() {
            this.$router.push({
                name: 'credit-platform-endorsement',
                query: { ContractSpecificationByConsult: this.items },
            });
        },

        generateExcel() {
            return this.items.map(item => ({
                expected_settlement_date: date(item.expected_settlement_date),
                receivable_debtor: taxId(item.receivable_debtor),
                payment_scheme: item.payment_scheme,
                effect_value: removeSymbolsMoney(value(item.effect_value)),
            }));
        },

        handleCalculeGuarantee(v) {
            this.calcule = { ...this.calcule, ...v };
        },
    },

};
</script>
