<template>
  <b-card>
    <b-row>
      <b-col md="12">
        <p> Total: {{ total }} </p>
        <p> Total Disponível: {{ totalAvailableUrs }}</p>
        <p> Total Comprometido: {{ totalCommitted }} </p>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="6">
        <b-form-group
          label="Filtro"
          label-for="filter"
        >
          <b-form-input
            id="filter"
            v-model="filter"
          />
        </b-form-group>
      </b-col>

      <b-col md="2">
        <b-form-group
          label="Efeito"
          label-for="effect"
        >
          <b-form-select
            id="effect"
            v-model="filterEffect"
            :options="[
              { value: null, text: 'Selecione um efeito'},
              { value: false, text: 'Não'},
              { value: true, text: 'Sim'}
            ]"
          />
        </b-form-group>
      </b-col>

      <b-col md="2">

        <JsonExcel
          name="urs.xls"
          class="btn d-block mx-auto my-2 btn-primary"
          :fetch="generateExcel"
        >
          Exportar em Excel
        </JsonExcel>
      </b-col>

      <b-col md="2">
        <b-button
          v-b-modal="'calculate-urs'"
          variant="primary"
          class="d-block mx-auto my-2"
          style="width: 100%;"
        >
          Calcular
        </b-button>
      </b-col>
    </b-row>

    <b-table
      class="text-center"
      :items="tableItems"
      :fields="fields"
      :current-page="currentPage"
      :filter="filter"
      :per-page="10"
      hover
      responsive
      @row-clicked="handleRowClick"
    >
      <template #cell(settled_amount)="data">
        {{ (data.item.settled_amount || 0) | value }}
      </template>

      <template #cell(expected_settlement_date)="data">
        {{ data.item.expected_settlement_date | date }}
      </template>

      <template #cell(acquirer)="data">
        {{ data.item.acquirer | tax_id }}
      </template>

      <template #cell(uncommitted_amount)="data">
        {{ data.item.uncommitted_amount | value }}
      </template>

      <template #cell(committed_amount)="data">
        {{ data.item.committed_amount | value }}
      </template>

      <template #cell(total_amount)="data">
        {{ (data.item.settled_amount || 0) + data.item.committed_amount + data.item.uncommitted_amount | value }}
      </template>

      <template #cell(effect)="data">
        <b-badge
          pill
          :variant="data.item.effect ? 'danger' : 'success'"
        >
          {{ data.item.effect ? "SIM" : "NÃO" }}
        </b-badge>
      </template>

      <template #cell(time)>
        {{ 'A Expirar' }}
      </template>
    </b-table>

    <b-pagination
      v-model="currentPage"
      :total-rows="tableItems.length"
      :per-page="10"
      align="center"
      size="sm"
      class="mt-2"
    />
    <b-modal
      id="calculate-urs"
      size="lg"
      hide-footer
    >
      <calculator-content
        :urs="items"
        :available-amount="availableAmount"
        :constituted="constituted"
      />
    </b-modal>
  </b-card>
</template>

<script>
import {
    BButton, BCard, BTable, BPagination, BRow, BCol, BBadge, BFormInput, BFormGroup, BFormSelect, BModal,
} from 'bootstrap-vue';

import JsonExcel from 'vue-json-excel';

import { exportExcel } from './export-excel/index';

import CalculatorContent from './calculator/Content.vue';

export default {
    components: {
        BButton,
        BCard,
        BTable,
        BBadge,
        BPagination,
        BRow,
        BCol,
        BFormInput,
        BFormGroup,
        BFormSelect,
        JsonExcel,
        CalculatorContent,
        BModal,
    },

    props: {
        items: {
            type: Array,
            default: () => [],
        },

        fields: {
            type: Array,
            default: () => [],
        },

        ursAvailable: {
            type: Number,
            default: () => 0,
        },

        availableAmount: {
            type: String,
            required: true,
        },

        ursCommited: {
            type: String,
            required: true,
        },

        total: {
            type: String,
            required: true,
        },

        totalAvailable: {
            type: String,
            required: true,
        },

        constituted: {
            type: Number,
            required: true,
        },
    },

    data() {
        return {
            currentPage: 1,
            filter: null,
            filterEffect: null,
        };
    },

    computed: {
        totalAvailableUrs() {
            return this.items.filter(item => !item?.hasEffect).length;
        },
        totalCommitted() {
            return this.total - this.totalAvailableUrs;
        },
        tableItems() {
            let { items } = this;

            if (this.filterEffect) {
                items = items.filter(item => item.effect);
            } else if (this.filterEffect === false) {
                items = items.filter(item => !item.effect);
            }

            return items;
        },
    },

    methods: {
        handleRowClick(e) {
            this.$store.commit('ur/UPDATE_SELECTED_UR', e.settlement_obligations[0]);
            this.$router.push('/plataforma-credito/ur');
        },

        doUncommittedAmount(item) {
            const expectedSettlementDate = new Date(item.expected_settlement_date).setHours(0, 0, 0, 0);
            const today = new Date().setHours(0, 0, 0, 0);

            if (expectedSettlementDate <= today) {
                return 0;
            }

            return item.uncommitted_amount;
        },

        calculatecommittedAmount(allContractObligations) {
            let committedAmount;

            if (!allContractObligations.length) {
                committedAmount = 0;
            } else if (allContractObligations[0].percentage_value) {
                committedAmount = `${allContractObligations.reduce((acc, curr) => acc + (curr.percentage_value / 100), 0)} %`;
            } else {
                committedAmount = allContractObligations.reduce((acc, curr) => acc + curr.effect_amount, 0);
            }

            return committedAmount;
        },

        generateExcel() {
            return exportExcel(this.items, this.doUncommittedAmount, this.calculatecommittedAmount);
        },
    },
};
</script>
