const urToContractSpecification = ur => ({
    receivable_debtor: ur.acquirer || ur.debtor,
    expected_settlement_date: ur.expected_settlement_date,
    payment_scheme: ur.payment_scheme,
    effect_value: ur.value,
});

function calculatePercentage(value, percentage) {
    return (value * percentage) / 100;
}

export default function calculator(urs, totalValue, guarantee = 100) {
    let result = 0;

    const calculatedContractSpecifications = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const ur of urs) {
        const { uncommitted_amount: value } = ur;
        const v = calculatePercentage(value, guarantee);

        // eslint-disable-next-line no-continue
        if (!value) continue;
        if (v + result < totalValue) {
            result += v;
            calculatedContractSpecifications.push(urToContractSpecification({ ...ur, value: v }));
        } else if (v + result >= totalValue) {
            calculatedContractSpecifications.push(urToContractSpecification({ ...ur, value: totalValue - result }));
            break;
        }
    }

    return calculatedContractSpecifications;
}
