import BaseOsirisService from './BaseOsirisService';

const BASE_SCHEDULE = '/tag/schedule';

class ConsultService extends BaseOsirisService {
    async findSchedule(data) {
        try {
            return await this.api.post(BASE_SCHEDULE, data);
        } catch ({ response }) {
            return response;
        }
    }

    async getSchedule(id) {
        try {
            return await this.api.get(`${BASE_SCHEDULE}/${id}`);
        } catch ({ response }) {
            return response;
        }
    }
}

export default new ConsultService();
