import { deepClone } from '@/helpers/deepClone';
import formatValue from '@/filters/value';
import formatTaxId from '@/filters/taxId';

export const exportExcel = (items, doUncommittedAmount, calculateCommitedAmount) => items.map(item => {
    const contractObligationBaseObject = {};
    const contractObligationMaxSize = (Math.max(...(items.map(item2 => item2.settlement_obligations[0].contract_obligations.length))));

    for (let contractObligationNumber = 1; contractObligationNumber <= contractObligationMaxSize; contractObligationNumber += 1) {
        const title = `Obrigação de Contrato ${contractObligationNumber}`;
        contractObligationBaseObject[`${title} / Previsão da Liquidação`] = '';
        contractObligationBaseObject[`${title} / Adquirente`] = '';
        contractObligationBaseObject[`${title} / Titular do Contrato`] = '';
        contractObligationBaseObject[`${title} / Beneficiário`] = '';
        contractObligationBaseObject[`${title} / Valor Efetivo`] = '';
    }
    const [firstSettlementObligation] = item.settlement_obligations;
    const hasEffect = firstSettlementObligation.contract_obligations.length > 0;

    const { contract_obligations: allContractObligations } = firstSettlementObligation;

    const contractObligations = allContractObligations.reduce((acc, contractObligation, index) => {
        const contractObligationNumber = index + 1;
        const title = `Obrigação de Contrato ${contractObligationNumber}`;
        return {
            ...acc,
            [`${title} / Previsão da Liquidação`]: item.expected_settlement_date,
            [`${title} / Adquirente`]: formatTaxId(contractObligation.trade_repository),
            [`${title} / Titular do Contrato`]: formatTaxId(contractObligation.contract_holder),
            [`${title} / Beneficiário`]: formatTaxId(contractObligation.bank_account.document_number),
            [`${title} / Valor Efetivo`]: contractObligation.percentage_value ? `${contractObligation.effect_amount} %` : (contractObligation.effect_amount),
        };
    }, deepClone(contractObligationBaseObject));

    return {
        'Data da UR': item.expected_settlement_date,
        Adquirente: formatTaxId(item.debtor),
        Esquema: item.payment_scheme,
        Comprometido: calculateCommitedAmount ? calculateCommitedAmount(item) : +(item.committed_amount.toFixed(2)),
        Autorizado: item.committed_effect_amount,
        Disponível: doUncommittedAmount(item),
        Liquidado: item.settled_amount,
        Total: item.total_amount,
        Efeito: hasEffect ? 'SIM' : 'NÃO',
        ...contractObligations,
    };
});
