<template>
  <div>

    <b-table
      :fields="primaryFields"
      class="text-center matrix-table"
      :items="items"
      :current-page="currentPage"
      :per-page="5"
      responsive
    />

    <b-pagination
      v-if="!hidePagination"
      v-model="currentPage"
      :total-rows="items.length"
      :per-page="5"
      align="center"
      size="sm"
      class="mt-2"
    />
  </div>
</template>

<script>
import { BTable, BPagination } from 'bootstrap-vue';

export default {
    components: {
        BTable,
        BPagination,
    },

    props: {
        primaryFields: {
            type: Array,
            default: () => [],
        },

        primaryKey: {
            type: String,
            default: () => '',
        },

        secondaryKey: {
            type: String,
            default: () => '',
        },

        itemKey: {
            type: String,
            default: () => '',
        },

        items: {
            type: Array,
            default: () => [],
        },

        hidePagination: {
            type: Boolean,
            default: () => false,
        },
    },

    data() {
        return {
            currentPage: 1,
        };
    },
};
</script>

<style lang="scss">
    .dark-layout {
        .matrix-table {
            tbody tr td:first-child {
                background-color: #343d55;
                color: #d0d2d6
            }
        }
    }
.matrix-table {
    tbody tr td:first-child {
        color: #6e6b7b;
          background-color: #f3f2f7;
          text-transform: uppercase;
          font-size: 0.857rem;
          letter-spacing: 0.5px;
          font-weight: 700;
          border-right: 1px solid #ebe9f1;
          border-top: none !important;
          width: 220px;
        }

  }

  .header:last-of-type {
    border-bottom: 1px solid #ebe9f1;
  }
</style>
