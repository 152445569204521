<template>
  <b-card>
    <heading class="text-center">
      Consulta
    </heading>

    <validation-observer
      #default="{ invalid }"
      ref="simpleRules"
    >
      <b-form>
        <b-row>
          <b-col md="4">
            <b-form-group
              label="Registradora*"
              label-for="register"
            >
              <validation-provider
                #default="{ errors }"
                name="Registradora"
                rules="required"
              >
                <b-form-select
                  id="register"
                  v-model="params.register"
                  :state="errors.length > 0 ? false : null"
                  disabled
                  :options="registerOptions"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Data Inicial*"
              label-for="inicial-date"
            >
              <validation-provider
                #default="{ errors }"
                name="Data Inicial"
                rules="required"
              >
                <b-form-input
                  id="inicial-date"
                  v-model="params.start_date"
                  :state="errors.length > 0 ? false : null"
                  :readonly="!editable"
                  type="date"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group
              label="Data Final*"
              label-for="final-date"
            >
              <validation-provider
                #default="{ errors }"
                name="Data Final"
                :rules="`required|end-date:${params.start_date}`"
              >
                <b-form-input
                  id="final-date"
                  v-model="params.end_date"
                  :readonly="!editable"
                  :state="errors.length > 0 ? false : null"
                  type="date"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <div class="d-flex justify-content-center">
          <b-button
            variant="primary"
            :disabled="invalid || !editable"
            @click="handleClickButton"
          >
            Consultar
          </b-button>
        </div>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BFormSelect,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import Heading from '@/views/components/Heading.vue';

import paymentSchemes from '@/utils/paymentSchemes';

import '@validations';

export default {
    components: {
        Heading,
        BFormSelect,
        BCard,
        BRow,
        BCol,
        BForm,
        BFormGroup,
        BFormInput,
        BButton,
        ValidationProvider,
        ValidationObserver,
    },
    props: {
        register: {
            type: String,
            required: true,
        },
        editable: {
            type: Boolean,
            default: () => true,
        },
        startDate: { type: String, default: () => '' },
        endDate: { type: String, default: () => '' },
    },
    data() {
        return {
            paymentSchemes,
            params: {
                register: this.register,
                start_date: this.startDate || null,
                end_date: this.endDate || null,
            },
        };
    },

    computed: {
        registerOptions() {
            return ['TAG', 'CERC'];
        },
    },

    methods: {
        handleClickButton() {
            this.$emit('clickConsultButton', this.params);
        },
    },
};
</script>
