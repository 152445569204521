<template>
  <b-row>
    <b-col md="6">
      <b-form-group
        label="Garantia"
        label-for="guarantee"
      >
        <b-input-group append="%">
          <b-form-input
            id="guarantee"
            v-model="guarantee.guarantee"
            placeholder="100"
          />
        </b-input-group>
      </b-form-group>
    </b-col>

    <b-col md="6">
      <b-form-group
        label="Parâmetro"
        label-for="param"
      >
        <b-form-select
          id="param"
          v-model="guarantee.param"
          :options="[
            // { value: 'available', text: 'Valor Disponível' },
            // { value: 'constituted', text: 'Valor Constituído'},
            { value: 'per_ur', text: 'Valor Disponível'}
          ]"
        />
      </b-form-group>
    </b-col>

    <b-col
      v-if="calcule.guarantee && calculate.guarantee !== 0"
      md="12"
    >
      <p> Garantia: {{ calcule.guarantee | value }} </p>

      <p> Disponível com Garantia: {{ calcule.availableTotal | value }} </p>
    </b-col>
  </b-row>
</template>

<script>
import {
    BRow, BCol, BInputGroup, BFormGroup, BFormInput, BFormSelect,
} from 'bootstrap-vue';

export default {
    components: {
        BCol, BRow, BInputGroup, BFormGroup, BFormInput, BFormSelect,
    },

    props: {
        available: {
            type: Number,
            required: true,
        },

        constituted: {
            type: Number,
            required: true,
        },
    },

    data() {
        return {
            guarantee: { param: 'available', guarantee: 0 },
            calcule: {
                constituted: 0,
            },
        };
    },

    watch: {

        // eslint-disable-next-line func-names
        guarantee: {
            handler() {
                this.calculate();
            },
            deep: true,
        },

        available() {
            this.calculate();
        },
    },

    mounted() {
        this.calculate();
    },

    methods: {
        calculate() {
            if (this.guarantee.param === 'constituted') {
                this.calcule.guarantee = this.calculateConstituited();
            } else {
                this.calcule.guarantee = this.calculateAvailable();
            }
            this.calcule.availableTotal = this.available - this.calcule.guarantee;
            this.calcule.guaranteeValue = +this.guarantee.guarantee;
            this.calcule.param = this.guarantee.param;

            this.$emit('calcule', this.calcule);
        },

        percentCalcule(value, percent) {
            return (value * (percent / 100));
        },

        calculateConstituited() {
            const { guarantee } = this.guarantee;
            const { constituted } = this;

            return this.percentCalcule(constituted, guarantee);
        },

        calculateAvailable() {
            const { guarantee } = this.guarantee;
            const { available } = this;

            return this.percentCalcule(available, guarantee);
        },
    },
};
</script>
