<template>
  <div>

    <FormHeader
      :register="register"
      :editable="!id"
      :start-date="formatDateToInput(schedule.start_date)"
      :end-date="formatDateToInput(schedule.end_date)"
      @clickConsultButton="(data) => $emit('consult', data)"
    />

    <Content
      v-if="!objectIsEmpty(schedule)"
      :schedule="schedule"
    />

  </div>
</template>

<script>
import FormHeader from './Header.vue';

import WebService from '@/services/osiris/WebService';

import Content from './Content.vue';

export default {
    components: {

        FormHeader,
        Content,
    },

    props: {
        schedule: { type: Object, required: true },
        register: { type: String, required: true },
    },

    data() {
        return {
            id: this.$route.params.id,
            renderPage: false,
            merchantId: null,
            enumContractHolderName: {},
            enumDebtors: {},
        };
    },

    watch: {
        schedule(schedule) {
            if (schedule) {
                this.doData(schedule);
            }
        },
    },

    methods: {

        async getAllTaxIdName(taxIds) {
            const set = new Set(taxIds);
            const array = Array.from(set);

            const promises = array.map(taxId => WebService.findCNPJ(taxId));

            const fetchedTaxIds = await Promise.all(promises);

            const taxIdsToLegalTaxName = fetchedTaxIds.reduce((acc, response) => {
                const { data, status } = response;

                const taxId = status === 200 ? data.tax_id : response.config.url.split('/')[1];

                const { legal_tax_name: legalTaxName, name } = data;
                const regex = /^[^a-zA-Z]*$/;
                const nameHasLetters = !regex.test(name);

                acc[taxId] = (nameHasLetters && name) || legalTaxName || taxId;

                return acc;
            }, {});

            return taxIdsToLegalTaxName;
        },

        objectIsEmpty(obj) {
            // eslint-disable-next-line no-restricted-syntax
            for (const prop in obj) {
                // eslint-disable-next-line no-prototype-builtins
                if (obj.hasOwnProperty(prop)) { return false; }
            }

            return true;
        },

        async doData(data) {
            const allContractHolders = data.contract_holders.map(item => item.contract_holder);
            const brands = data.debtors.map(({ debtor }) => debtor);

            const [contractHoldersName, debtorsName] = await Promise.all([
                this.getAllTaxIdName(allContractHolders),
                this.getAllTaxIdName(brands),
            ]);

            this.enumContractHolderName = contractHoldersName;
            this.enumDebtors = debtorsName;

            this.schedule.start_date = this.formatDateToInput(data.start_date);
            this.schedule.end_date = this.formatDateToInput(data.end_date);
        },
    },
};
</script>
