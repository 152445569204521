<template>
  <b-container fluid>
    <CreditPlatformProductLayout
      :breadcrumb-items="[
        {
          text: 'Inicio',
          href: '/plataforma-credito',
        },
        {
          text: 'Detalhes Estabelecimento',
          href: `/plataforma-credito/estabelecimento/detalhes/${merchant.id}`,
        },
        {
          text: 'Agenda',
          href: `/plataforma-credito/optin/${optinId}/agenda`,
        },
        {
          text: 'Consulta',
        },
      ]"
    >

      <Page
        :schedule="schedule"
        :register="register"
        @consult="findSchedule"
      />
    </CreditPlatformProductLayout>
  </b-container>
</template>

<script>
import { BContainer } from 'bootstrap-vue';
import ConsultService from '@/services/osiris/ConsultService';

import CercConsultService from '@/services/osiris/Cerc/ScheduleService';

import Page from './components/Page.vue';

import { registerValidator } from '@/utils/registerValidator';

import CreditPlatformProductLayout from '@/views/layouts/CreditPlatformItemProductLayout.vue';
import ConsultMapperFactory from './mappers';

const services = {
    CERC: CercConsultService,
    TAG: ConsultService,
};

export default {
    components: {
        BContainer,
        Page,
        CreditPlatformProductLayout,
    },

    data() {
        return {
            register: registerValidator(this.$route.params.register),
            optinId: +this.$route.params.optin,
            schedule: {},
            merchant: this.getStoreMerchant(),
        };
    },

    methods: {
        async findSchedule(params) {
            const isConfirmed = await this.confirmAnAction('Deseja realmente realizar essa consulta?');

            if (!isConfirmed) return;
            this.renderPage = false;
            this.isLoading(true);

            const merchant = this.getStoreMerchant();

            const request = {
                start_date: params.start_date,
                end_date: params.end_date,
                opt_in_id: this.optinId,
                document_number: merchant.asset_holder,
                payment_scheme: undefined,
                acquirers: [],
                payment_schemes: [],
            };

            const { register } = params;

            const { data: responseData, status } = await services[register].findSchedule(request);

            if (status === 200) {
                const data = ConsultMapperFactory.generate(register).map(responseData);
                this.schedule = data;
            } else if (status === 204) {
                this.modalError('Consulta realizada! O estabelecimento não possui agenda');
            } else if (status === 403) { this.modalError('Está fora do horário de funcionamento, tente mais tarde'); } else if (status === 404) {
                this.modalError('Opt-in não encontrado');
            } else {
                this.modalError('Erro na consulta');
            }
            this.renderPage = true;
            this.isLoading(false);
        },
    },
};
</script>
