<template>
  <b-row>
    <b-col md="6">
      <p> Constituído: {{ value.constituted | value }}</p>
      <p> Disponível: {{ value.available | value }}</p>
    </b-col>

    <b-col md="6">
      <p> Data Inicial: {{ date.min | date }}</p>
      <p> Data Final: {{ date.max | date }}</p>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue';

export default {
    components: { BRow, BCol },

    props: {
        value: {
            type: Object,
            required: true,
        },

        date: {
            type: Object,
            required: true,
        },
    },
};
</script>
