import BaseOsirisService from './BaseOsirisService';

class WebService extends BaseOsirisService {
    async findCNPJ(cnpj) {
        try {
            return await this.api.get(`cnpj/${cnpj}`);
        } catch ({ response }) {
            return response;
        }
    }
}

export default new WebService();
