const schemesPriority = [
    'MCC',
    'MCD',
    'VCC',
    'VCD',
    'ECC',
    'ECD',
];

export default schemesPriority;
