<template>
  <b-row>
    <b-col md="6">
      <b-form-group
        label="Data Inicial"
        label-for="start-date"
      >
        <validation-provider
          #default="{ errors }"
          name="Data Inicial"
          rules="required|after-today"
        >
          <b-form-input
            id="start-date"
            v-model="dates.startDate"
            :state="errors.length > 0 ? false:null"
            type="date"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>

    <b-col md="6">
      <b-form-group
        label="Data Final"
        label-for="end-date"
      >
        <validation-provider
          #default="{ errors }"
          name="Data Inicial"
          :rules="`required|after-date:${dates.startDate}`"
        >
          <b-form-input
            id="end-date"
            v-model="dates.endDate"
            :state="errors.length > 0 ? false : null"
            type="date"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import {
    BRow, BCol, BFormGroup, BFormInput,
} from 'bootstrap-vue';

import { ValidationProvider } from 'vee-validate';

export default {
    components: {
        BRow, BCol, BFormGroup, BFormInput, ValidationProvider,
    },

    props: {
        dates: {
            type: Object,
            required: true,
        },
    },
};
</script>
